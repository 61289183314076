exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-study-1-js": () => import("./../../../src/pages/case-study-1.js" /* webpackChunkName: "component---src-pages-case-study-1-js" */),
  "component---src-pages-case-study-2-js": () => import("./../../../src/pages/case-study-2.js" /* webpackChunkName: "component---src-pages-case-study-2-js" */),
  "component---src-pages-case-study-3-js": () => import("./../../../src/pages/case-study-3.js" /* webpackChunkName: "component---src-pages-case-study-3-js" */),
  "component---src-pages-case-study-4-js": () => import("./../../../src/pages/case-study-4.js" /* webpackChunkName: "component---src-pages-case-study-4-js" */),
  "component---src-pages-case-study-5-js": () => import("./../../../src/pages/case-study-5.js" /* webpackChunkName: "component---src-pages-case-study-5-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

